import React from 'react';
import styled, { keyframes } from 'styled-components';

function Loader() {
  return (
    <StyledLoader>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </StyledLoader>
  );
}

export default Loader;

const animation1 = keyframes`
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;
const animation2 = keyframes`
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
`;

const animation3 = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
`;

const StyledLoader = styled.div`
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 40px;
  height: 13px;

  & > div {
    position: absolute;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: #fff;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
  }

  & div:nth-child(1) {
    left: 8px;
    animation: ${animation1} 0.6s infinite;
  }
  & div:nth-child(2) {
    left: 8px;
    animation: ${animation2} 0.6s infinite;
  }
  & div:nth-child(3) {
    left: 32px;
    animation: ${animation2} 0.6s infinite;
  }
  & div:nth-child(4) {
    left: 56px;
    animation: ${animation3} 0.6s infinite;
  }
`;
