import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';

import ContentContainer from '@/components/ContentContainer';
import Link from '@/components/Link';
import { colors } from '@/constants/theme';
import Button from '@/components/Button';
import { media } from '@/utils/mixin';
import { useTypedSelector } from '@/store/store';
import { selectMenuItemListByAlias } from '@/store/reducers/tager/menus';
import { ReactComponent as BurgerIconSvg } from '@/assets/svg/burger-icon.svg';
import MobileMenu from '@/components/MobileMenu';
import useHeader from '@/hooks/useHeader';
import useForm from '@/hooks/useForm';

function isActivePage(to: string, path: string) {
  return to.split('?')[0] === path.split('?')[0];
}

function Header() {
  const [isShowMobileMenu, setShowMobileMenu] = useState(false);
  const [state] = useHeader();
  const menuItemList =
    useTypedSelector((state) => selectMenuItemListByAlias(state, 'header')) ??
    [];

  const router = useRouter();
  const [openForm] = useForm();

  return (
    <HeaderContainer isWhiteTheme={state}>
      <ContentContainer>
        <HeaderWrapper>
          <HeaderInner>
            <LogoWrapper>
              <LogoTitle>
                <Link to="/">solution</Link>
              </LogoTitle>
            </LogoWrapper>
            <MenuWrapper>
              <MenuInnerWrapper>
                {menuItemList.map((menuItem) => (
                  <MenuItem key={menuItem.id}>
                    <MenuLink
                      isWhiteTheme={state}
                      to={menuItem.link}
                      isActive={isActivePage(
                        menuItem.link ?? '',
                        router.asPath
                      )}
                      target={menuItem.isNewTab ? '_blank' : undefined}
                    >
                      {menuItem.label}
                    </MenuLink>
                  </MenuItem>
                ))}
                <MenuItem>
                  <FeedBackButton onClick={openForm} isWhiteTheme={state}>
                    <FeedBackButtonTitle isWhiteTheme={state}>
                      Связаться с нами
                    </FeedBackButtonTitle>
                  </FeedBackButton>
                </MenuItem>
              </MenuInnerWrapper>
            </MenuWrapper>
            <BurgerIcon
              isWhiteColor={state}
              onClick={() => setShowMobileMenu(!isShowMobileMenu)}
            >
              <BurgerIconSvg />
              {isShowMobileMenu ? (
                <MobileMenu
                  closeMenu={() => setShowMobileMenu((prev) => !prev)}
                />
              ) : null}
            </BurgerIcon>
          </HeaderInner>
        </HeaderWrapper>
      </ContentContainer>
    </HeaderContainer>
  );
}

const HeaderContainer = styled.header<{ isWhiteTheme: boolean }>`
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  border-bottom: 1px solid
    ${({ isWhiteTheme }) =>
      isWhiteTheme ? `#B6B6C0` : `rgba(255, 255, 255, 0.4)`};
  z-index: 500;
  color: ${({ isWhiteTheme }) => (isWhiteTheme ? colors.black : colors.white)};
  background: ${({ isWhiteTheme }) =>
    isWhiteTheme ? colors.white : `transparent`};
`;

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 70px;
  height: 100%;
`;

const HeaderInner = styled.div`
  flex: 1 1 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BurgerIcon = styled.div<{ isWhiteColor: boolean }>`
  display: none;
  cursor: pointer;

  ${({ isWhiteColor }) =>
    isWhiteColor
      ? css`
          svg path {
            fill: ${colors.dark};
          }
        `
      : null}

  ${media.mobile(css`
    display: block;
  `)}
`;

const MenuLink = styled(Link)<{ isActive?: boolean; isWhiteTheme: boolean }>`
  position: relative;
  display: block;
  padding: 24px 31px;
  font-weight: 600;
  font-size: 15px;
  line-height: 22px;
  cursor: pointer;
  transition: all 250ms ease-in-out;

  &:hover {
    ${({ isActive }) =>
      isActive
        ? null
        : css`
            color: ${colors.pink};
          `}
  }

  &:hover:before {
    ${({ isActive }) =>
      isActive
        ? null
        : css`
            background-color: ${colors.pink};
          `}
  }

  &:before {
    position: absolute;
    content: '';
    bottom: -2px;
    left: 0;
    right: 0;
    width: 100%;
    height: 2px;
    border-radius: 2px;
    transition: all 250ms ease-in-out;

    ${({ isActive, isWhiteTheme }) =>
      isActive
        ? css`
            & {
              background: ${isWhiteTheme ? colors.dark : colors.white};
            }
          `
        : null}
  }
`;

const FeedBackButtonTitle = styled.span<{ isWhiteTheme: boolean }>`
  position: relative;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;
  text-transform: initial;
  transition: all 0.3s ease;
  z-index: 3;
  ${({ isWhiteTheme }) =>
    isWhiteTheme
      ? css`
          background: ${colors.gradient};
          background-clip: text;
          -webkit-background-clip: text;
          color: rgba(0, 0, 0, 0);
        `
      : css`
          color: ${colors.white};
        `}
`;

const FeedBackButton = styled(Button)<{ isWhiteTheme: boolean }>`
  position: relative;
  min-width: 160px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-height: 40px;
  transition: all 0.5s ease;

  letter-spacing: 0px;
  border-radius: 40px;
  overflow: hidden;

  ${({ isWhiteTheme }) =>
    isWhiteTheme
      ? css`
          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            border-radius: 40px;
            padding: 2px;
            background: ${colors.gradient};
            -webkit-mask: linear-gradient(#fff 0 0) content-box,
              linear-gradient(#fff 0 0);
            -webkit-mask-composite: destination-out;
            mask-composite: exclude;
            z-index: 2;
          }

          &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background: ${colors.gradient};
            border-radius: 40px;
            opacity: 0;
            z-index: -1;
            transition: all 0.5s ease;
          }

          &:hover {
            span {
              color: rgba(255, 255, 255, 1);
            }

            &:after {
              opacity: 1;
            }
          }
        `
      : css`
          border: 2px solid ${colors.white};

          &:hover {
            background-color: ${colors.white};

            span {
              color: ${colors.pink};
            }
          }
        `}
`;

const LogoWrapper = styled.div``;

const LogoTitle = styled.span`
  display: block;
  font-weight: bold;
  font-size: 24px;
  line-height: 35px;
  text-transform: uppercase;

  a {
    letter-spacing: 1.4px;
  }
`;

const MenuWrapper = styled.div`
  ${media.mobile(css`
    display: none;
  `)}
`;

const MenuInnerWrapper = styled.ul`
  display: flex;
  align-items: center;
`;

const MenuItem = styled.li`
  margin-right: 25px;

  &:nth-child(2) {
    margin-right: 34px;
  }

  &:nth-child(1) {
    margin-right: 12px;
  }

  &:last-child {
    margin-right: 0;
  }
`;

export default Header;
