import { useContext } from 'react';

import { FormContext } from '@/provider/FormProvider';

function useForm(): [() => void] {
  const context = useContext(FormContext);

  if (!context) {
    throw new Error('use only in  context');
  }

  return [context.openModal];
}

export default useForm;
