import { request } from '@tager/web-core';

import { FormValuesType } from '@/components/Form/FormInner';

export function getExampleList() {
  return request.get({ path: '/tager/example' });
}

export function createExample() {
  return request.post({ path: '/tager/example', body: { name: 'example' } });
}

export function submitLeads(payload: FormValuesType) {
  return request.post({ path: '/leads', body: payload });
}
