import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';

import { Preloader } from '@tager/web-components';

import useForm from '@/hooks/useForm';

import Header from './components/Header';
import Footer from './components/Footer';

type Props = {
  children?: React.ReactNode;
  isPreloaderHidden?: boolean;
};

function Layout({ children, isPreloaderHidden }: Props) {
  const router = useRouter();
  const [openForm] = useForm();

  useEffect(() => {
    const linksForm = document.querySelectorAll('.link-form');

    function resetListeners() {
      linksForm.forEach((item) => {
        item.removeEventListener('click', openForm);
      });
    }

    if (linksForm.length !== 0) {
      resetListeners();
      linksForm.forEach((item) => {
        item.addEventListener('click', openForm);
      });
    }

    return () => {
      resetListeners();
    };
  }, [router.query]);
  return (
    <Container>
      <Preloader hidden={isPreloaderHidden} />

      <Header />
      <Main>{children}</Main>
      <Footer />
    </Container>
  );
}

const Container = styled.div`
  background-color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Main = styled.main`
  flex: 1 0 auto;
  display: flex;
  flex-direction: column;
`;

export default Layout;
