import React, { useEffect } from 'react';
import styled, { keyframes } from 'styled-components';

import { scroller } from '@tager/web-core';

import { ReactComponent as CloseIcon } from '@/assets/svg/close-icon.svg';
import { colors } from '@/constants/theme';
import Link from '@/components/Link';

interface Props {
  closeMenu: () => void;
}

function MobileMenu({ closeMenu }: Props) {
  useEffect(() => {
    scroller.lock();
    return () => {
      scroller.unlock();
    };
  });

  return (
    <MenuOverLay>
      <Menu>
        <WrapperCloseIcon>
          <CloseIcon onClick={closeMenu} />
        </WrapperCloseIcon>
        <MenuList>
          <MenuItem>
            <MenuItemLink to="/">O компании</MenuItemLink>
          </MenuItem>
          <MenuItem>
            <MenuItemLink to="/cdp">Customer data platform</MenuItemLink>
          </MenuItem>
        </MenuList>
      </Menu>
    </MenuOverLay>
  );
}

const animationMenu = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

const animationOverlay = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const MenuOverLay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);

  animation-name: ${animationOverlay};
  animation-duration: 0.3s;
`;

const Menu = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation-name: ${animationMenu};
  animation-duration: 0.5s;
  background: ${colors.dark};
`;

const WrapperCloseIcon = styled.div`
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 5;
  cursor: pointer;
`;

const MenuList = styled.ul`
  margin-top: 80px;
  padding: 0 20px;
`;

const MenuItem = styled.li`
  margin-top: 40px;

  &:first-child {
    margin-top: 0;
  }
`;
const MenuItemLink = styled(Link)`
  display: block;
  width: 100%;
  color: ${colors.white};
  font-size: 30px;
  line-height: 130%;
`;
export default MobileMenu;
