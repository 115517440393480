import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import { FormValuesType } from '@/components/Form/FormInner';
import { ReactComponent as ArrowIcon } from '@/assets/svg/arrow-down-2.svg';

function SelectList({
  options,
  value,
  setOpenDropDownList,
  setField,
  name,
}: {
  options: Array<string>;
  value: string;
  setField: (name: string, value: string) => void;
  setOpenDropDownList: (value: boolean) => void;
  name: string;
}) {
  const ref = useRef<HTMLDivElement>(null);
  const [positionTop, setPosition] = useState<boolean>(false);

  function getCurrentPosition() {
    if (ref.current) {
      const coordinate = ref.current.getBoundingClientRect();
      return coordinate.bottom > window.innerHeight;
    }
    return false;
  }

  useLayoutEffect(() => {
    const position = getCurrentPosition();
    setPosition(position);
  }, []);

  useEffect(() => {
    function handleClickByOutSide(event: MouseEvent) {
      if (ref.current && event.target instanceof Element) {
        if (event.target.contains(ref.current)) {
          setOpenDropDownList(false);
        }
      }
    }

    document.addEventListener('click', handleClickByOutSide);
    return () => document.addEventListener('click', handleClickByOutSide);
  }, []);

  return (
    <OptionContainer
      className="stylized-scroll"
      ref={ref}
      positionTop={positionTop}
    >
      {options &&
        options.length !== 0 &&
        options.map((option, index) => (
          <OptionGroup
            onClick={() => {
              setField(name, option);
              setOpenDropDownList(false);
            }}
            key={index}
            isActive={value === option}
          >
            <OptionLabel htmlFor={option}>{option}</OptionLabel>
            <Option />
          </OptionGroup>
        ))}
    </OptionContainer>
  );
}

function Select({
  options,
  value,
  error,
  name,
  setField,
  defaultValue = 'Select',
}: {
  options: Array<string>;
  name: string;
  value: string;
  setField: (name: string, value: string) => void;
  values: FormValuesType;
  defaultValue?: string;
  error?: string;
}) {
  const [isOpenDropDownList, setOpenDropDownList] = useState(false);

  return (
    <Container>
      <SelectedOption onClick={() => setOpenDropDownList(!isOpenDropDownList)}>
        <StyledIcon isOpen={isOpenDropDownList} />
        {!!value ? (
          <SelectedValue>{value}</SelectedValue>
        ) : (
          <DefaultValue>{defaultValue}</DefaultValue>
        )}
      </SelectedOption>
      {error ? <Error>{error}</Error> : null}
      {isOpenDropDownList ? (
        <SelectList
          setField={setField}
          name={name}
          value={value}
          options={options}
          setOpenDropDownList={setOpenDropDownList}
        />
      ) : null}
    </Container>
  );
}

const Container = styled.div`
  position: relative;
`;

const DefaultValue = styled.span`
  color: ${colors.dark};
`;
const SelectedValue = styled.span`
  color: ${colors.dark};
`;

const Error = styled.span`
  position: absolute;
  left: 0;
  bottom: -30px;
  color: red;
`;

const StyledIcon = styled(ArrowIcon)<{ isOpen: boolean }>`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 20px;
  transition: transform 0.4s;

  ${({ isOpen }) =>
    isOpen
      ? css`
          transform: translateY(-50%) rotate(180deg);
        `
      : null}

  ${media.mobile(css`
    right: 20px;
  `)}
`;

const SelectedOption = styled.div<{
  withPrefix?: boolean;
  invalid?: boolean;
}>`
  min-height: 48px;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 0.375rem 0.75rem;
  letter-spacing: -0.01em;
  -webkit-transition: all 250ms ease-in-out;
  transition: all 250ms ease-in-out;
  cursor: pointer;
  color: #c1c1c1;
  border: 1px solid #ced4da;
  font-size: 16px;
  line-height: 24px;

  & > span {
    transition: color 250ms ease-in-out;
  }

  &:hover {
    & > span {
      color: ${colors.black};
    }
  }

  ${media.mobile(css`
    font-size: 18px;
  `)}
`;

const OptionGroup = styled.div<{ isActive: boolean }>`
  position: relative;
  min-height: 48px;
  transition: background 150ms ease-in-out;
  ${({ isActive }) =>
    isActive
      ? css`
          background: #80b0fa;
        `
      : css`
          &:hover {
            background: #f2f2f3;
          }
        `}

  border-bottom: 1px solid #ced4da;

  &:last-child {
    border: none;
  }
`;

const OptionLabel = styled.label`
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  font-size: 16px;
  line-height: 24px;
  padding: 0.375rem 0.75rem;
  color: ${colors.black};
  cursor: pointer;
`;

const OptionContainer = styled.div<{ positionTop: boolean }>`
  position: absolute;
  max-width: 100%;
  width: 100%;
  background: #fff;
  z-index: 100;
  box-shadow: 0px 8px 26px #f3f3f3;
  max-height: 194px;
  overflow-y: auto;

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  ::-webkit-scrollbar-track {
    background-color: rgba(208, 206, 206, 0.1);
  }

  ::-webkit-scrollbar-thumb {
    height: 50px;
    background-color: #b5c9d6;
    border-radius: 3px;
  }

  scrollbar-color: #b5c9d6 rgba(208, 206, 206, 0.1);
  scrollbar-width: thin;

  ${({ positionTop }) =>
    positionTop
      ? css`
          top: -156%;
          ${media.mobile(css`
            top: -400%;
          `)}
        `
      : css`
          top: 102%;
        `}

  border: 1px solid #ced4da;
`;

const Option = styled.input`
  display: none;
  width: 100%;
  font-weight: normal;
  font-size: 31px;
  line-height: 24px;
  padding: 35px 0 35px 46px;
  text-transform: uppercase;
  appearance: none;
`;

export default Select;
