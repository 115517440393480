import React, { ButtonHTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

import Button from '@/components/Button';
import { colors } from '@/constants/theme';

export type CssSnippet = ReturnType<typeof css>;

type ButtonKeysType = 'primary' | 'secondary';

type StyleType = 'colorBg' | 'notColorBg' | 'common';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  styleType?: ButtonKeysType;
  isBgColor?: boolean;
  children: React.ReactNode;
}

const cssMap: Record<ButtonKeysType, Record<StyleType, CssSnippet>> = {
  primary: {
    common: css`
      max-width: 216px;
      width: 100%;
      min-height: 60px;
    `,
    colorBg: css`
      background: ${colors.white};
      transition: all 0.3s linear;
      border: 2px solid transparent;

      &:hover {
        color: ${colors.white};
        background: rgba(255, 255, 255, 0);
        border-color: ${colors.white};
      }

      &:disabled {
        background: #d0caeb;
        color: ${colors.dark};
      }
    `,
    notColorBg: css`
      background: linear-gradient(
        94.4deg,
        #ec6a81 -4.88%,
        #964589 47.81%,
        #4856d8 102.74%
      );
      color: ${colors.white};

      &:hover:before {
        //background: ${colors.pink};
        opacity: 1;
      }

      &:before {
        position: absolute;
        content: '';
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(
          273.69deg,
          #ec6a81 -12.6%,
          #964589 45.64%,
          #4856d8 106.35%
        );
        z-index: -1;
        transition: opacity 0.5s linear;
        opacity: 0;
        border-radius: 40px;
      }

      &:disabled {
        background: linear-gradient(
          94.4deg,
          rgba(236, 106, 129, 0.5) -4.88%,
          rgba(150, 69, 137, 0.5) 47.81%,
          rgba(72, 86, 216, 0.5) 102.74%
        );
      }
    `,
  },
  secondary: {
    common: css`
      max-width: 160px;
      width: 100%;
      min-height: 40px;
    `,
    notColorBg: css`
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background: linear-gradient(
        94.4deg,
        #ec6a81 -4.88%,
        #964589 47.81%,
        #4856d8 102.74%
      );
      border: 1px solid transparent;
      border-image: linear-gradient(
        94.4deg,
        #ec6a81 -4.88%,
        #964589 47.81%,
        #4856d8 102.74%
      );

      &:hover {
        background: linear-gradient(
          94.4deg,
          rgba(236, 106, 129, 0.5) -4.88%,
          rgba(150, 69, 137, 0.5) 47.81%,
          rgba(72, 86, 216, 0.5) 102.74%
        );
        color: ${colors.white};
      }

      &:disabled {
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background: linear-gradient(
          94.4deg,
          #ec6a81 -4.88%,
          #964589 47.81%,
          #4856d8 102.74%
        );
        border: 1px solid transparent;
        border-image: linear-gradient(
          94.4deg,
          #ec6a81 -4.88%,
          #964589 47.81%,
          #4856d8 102.74%
        );

        opacity: 0.5;
      }
    `,
    colorBg: css`
      color: ${colors.white};
      border: 1px solid ${colors.white};
      background: none;

      &:hover {
        color: ${colors.pink};
        border: 1px solid transparent;
        background: white;
      }

      &:disabled {
        color: ${colors.pink};
        border: 1px solid ${colors.white};
        background: none;
        opacity: 0.3;
      }
    `,
  },
};

function StyledButton({
  styleType = 'primary',
  isBgColor = false,
  children,
  ...rest
}: Props) {
  return (
    <CustomButton {...rest} styleType={styleType} isBgColor={isBgColor}>
      {children}
    </CustomButton>
  );
}

const CustomButton = styled(Button)<{
  styleType: ButtonKeysType;
  isBgColor: boolean;
}>`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  font-size: 20px;
  line-height: 24px;
  text-transform: none;
  font-weight: normal;
  letter-spacing: 0;
  transition: color 0.3s linear;
  z-index: 1;

  ${({ styleType }) => cssMap[styleType].common}
  ${({ styleType, isBgColor }) =>
    isBgColor ? cssMap[styleType].colorBg : cssMap[styleType].notColorBg}
`;

export default StyledButton;
