import React, { useState } from 'react';

export interface HeaderContextType {
  isChangeColor: boolean;
  setState: (value: boolean) => void;
}

export const Context = React.createContext<HeaderContextType | null>(null);

function HeaderProvider({ children }: { children: React.ReactNode }) {
  const [isChangeColor, setState] = useState(false);

  const values = {
    isChangeColor,
    setState,
  };
  return <Context.Provider value={values}>{children}</Context.Provider>;
}

export default HeaderProvider;
