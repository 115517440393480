import { useContext } from 'react';

import { Context } from '@/provider/HeaderProvider';

function useHeader(): [boolean, (value: boolean) => void] {
  const context = useContext(Context);

  if (!context) {
    throw new Error('use only in  context');
  }

  return [context.isChangeColor, context.setState];
}

export default useHeader;
