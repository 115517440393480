import React from 'react';
import styled, { css } from 'styled-components';

import useSettingItem from '@/hooks/useSettingItem';
import { colors } from '@/constants/theme';
import StyledButton from '@/components/StyledButton';
import { ReactComponent as CloseIcon } from '@/assets/svg/close-icon.svg';
import { media } from '@/utils/mixin';

function FormSuccess({ closeModal }: { closeModal: () => void }) {
  const successTitle = useSettingItem('FORM_SUCCESS_TITLE');
  const successText = useSettingItem('FORM_SUCCESS_TEXT');
  return (
    <Component>
      <Title>{successTitle}</Title>
      <Text>{successText}</Text>
      <Button onClick={closeModal}>Закрыть</Button>
      <WrapperIcon onClick={closeModal}>
        <CloseIcon />
      </WrapperIcon>
    </Component>
  );
}

const Component = styled.div`
  max-width: 800px;
  width: 100%;
  padding: 60px 56px 60px 78px;
  background: ${colors.white};

  @media (max-height: 900px) {
    height: 100vh;
    min-width: 100vw;
    padding: 60px 20px;
  }

  ${media.tabletSmall(css`
    height: 100vh;
    min-width: 100vw;
    padding: 60px 20px;
  `)}
`;

const Title = styled.span`
  display: block;
  font-size: 30px;
  line-height: 130%;
`;

const Text = styled.p`
  margin-top: 20px;
  font-size: 20px;
  line-height: 150%;
`;

const Button = styled(StyledButton)`
  margin-top: 40px;
`;

const WrapperIcon = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;

  svg line {
    stroke: ${colors.black};
  }
`;
export default FormSuccess;
