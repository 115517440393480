export const colors = {
  black: 'black',
  white: '#fff',
  black100: '#101010',
  black200: '#262626',
  black300: '#3b3b3b',
  gray: '#888888',
  grayDark: '#5f5f5f',
  grayLight: '#999999',
  gray100: '#F3F8F9',
  gray200: '#ced4da',
  red: 'red',
  pink: '#964589',
  dark: '#0C0A2C',
  purple: '#784CA8',
  gradient: `linear-gradient(128.73deg, #EC6A81 5.54%, #964589 48.55%, #4856D8 96.33%);`,
  pinkGradient: `linear-gradient(152.63deg, #E26682 1.38%, #AC4F87 88.96%)`,
  purpleGradient: `linear-gradient(152.63deg, #AD4F87 1.38%, #7B4BA4 88.96%)`,
  blueGradient: `linear-gradient(152.63deg, #7E4AA1 1.38%, #4E55D1 88.96%)`,
} as const;

/** Source: https://htmlacademy.ru/blog/useful/css/short-14 */
const fallbackFont = [
  '-apple-system',
  "'BlickMacSystemFont'",
  "'Segoe UI'",
  "'Roboto'",
  "'Oxygen'",
  "'Ubuntu'",
  "'Cantarell'",
  "'Fira Sans'",
  "'Droid Sans'",
  "'Helvetica Neue'",
  'sans-serif',
].join(',');

export const fonts = {
  Montserrat: `'Montserrat', ${fallbackFont}`,
  Jost: `'Jost', ${fallbackFont}`,
};

/**
 * Reference - Screen Resolution Stats Worldwide:
 * https://gs.statcounter.com/screen-resolution-stats
 *
 * 16px - scrollbar width on Windows,
 * some browsers doesn't include scrollbar width when calculate media queries
 */
export const breakpoints = {
  /** iPhone 5/SE */
  mobileSmall: 320,
  /** iPhone 6/7/8/X */
  mobileMedium: 375,
  /** iPhone 6/7/8 Plus */
  mobileLarge: 414,
  /** iPad 1, 2, Mini and Air */
  tabletSmall: 768,
  tabletLarge: 1024,
  /** 1280 - 16 = 1264 -> 1260 - more beautiful number :) */
  laptop: 1260,
  /** 1536 - 16 = 1520 -> 1500 - more beautiful number :) */
  laptopLarge: 1441,
  desktop: 1500,
};
