import React from 'react';
import styled, { css } from 'styled-components';

import { SmallContentContainer } from '@/components/ContentContainer';
import Link from '@/components/Link';
import { colors } from '@/constants/theme';
import { useTypedSelector } from '@/store/store';
import { selectMenuItemListByAlias } from '@/store/reducers/tager/menus';
import useSettingItem from '@/hooks/useSettingItem';
import { media } from '@/utils/mixin';

function Footer() {
  const footerItemList =
    useTypedSelector((state) => selectMenuItemListByAlias(state, 'footer')) ??
    [];

  const footerCopyright = useSettingItem('FOOTER_COPYRIGHT');
  const footerLinkLabel = useSettingItem('FOOTER_LINK_LABEL');
  const footerLinkUrl = useSettingItem('FOOTER_LINK_URL');

  return (
    <FooterContainer>
      <SmallContentContainer>
        <InnerWrapper>
          <Logo>solution</Logo>
          <FooterLinks>
            {footerItemList.map((footerItem) => (
              <StyledLink
                key={footerItem.id}
                to={footerItem.link}
                target={footerItem.isNewTab ? '_blank' : '_self'}
              >
                {footerItem.label}
              </StyledLink>
            ))}
          </FooterLinks>
        </InnerWrapper>
        <CopyRight>
          {footerCopyright}{' '}
          {footerLinkLabel ? (
            <Link to={footerLinkUrl ?? ''}>{footerLinkLabel}</Link>
          ) : null}
        </CopyRight>
      </SmallContentContainer>
    </FooterContainer>
  );
}

const FooterContainer = styled.footer`
  background: #0c0a2c;
  padding: 46px 0 52px;
  z-index: 100;
`;

const Logo = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  font-size: 24px;
  line-height: 35px;
  color: ${colors.white};
  letter-spacing: 1.5px;
`;

const InnerWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  ${media.mobile(css`
    flex-direction: column;
    align-items: flex-start;
  `)}
`;

const FooterLinks = styled.div`
  color: ${colors.white};

  ${media.mobile(css`
    flex-direction: column;
    margin-top: 20px;
  `)}
`;

const StyledLink = styled(Link)`
  margin-right: 76px;
  transition: all 250ms ease-in-out;
  font-weight: 600;
  font-size: 15px;
  line-height: 24px;

  &:hover {
    color: ${colors.pink};
  }

  &:last-child {
    margin-right: 1.5px;
  }

  ${media.mobileMedium(css`
    margin-right: 32px;
  `)}
`;

const CopyRight = styled.div`
  margin-top: 20px;
  color: rgba(255, 255, 255, 0.5);
  font-size: 16px;
  line-height: 141.3%;

  a {
    display: inline;
    position: relative;

    &:before {
      display: block;
      position: absolute;
      content: '';
      bottom: 1px;
      right: 0;
      left: 0;
      height: 1.5px;
      background: rgba(255, 255, 255, 0.5);
    }

    &:hover {
      text-decoration: none;
    }

    ${media.tabletSmall(css`
      margin-top: 20px;
    `)}

    ${media.mobile(css`
      margin-top: 17px;
      display: inline-flex;
    `)}
  }

  ${media.tabletSmall(css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `)}

  ${media.mobile(css`
    display: block;
  `)}
`;

export default Footer;
