import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { Formik, FormikErrors, FormikHelpers } from 'formik';

import { convertRequestErrorToMap } from '@tager/web-core';

import { submitLeads } from '@/services/requests';
import useSettingItem from '@/hooks/useSettingItem';
import { colors, fonts } from '@/constants/theme';
import { TextInputFormik } from '@/components/TextInput';
import StyledButton from '@/components/StyledButton';
import { ReactComponent as CheckedIcon } from '@/assets/svg/checked.svg';
import { ReactComponent as CloseIcon } from '@/assets/svg/close-icon.svg';
import { media } from '@/utils/mixin';
import Select from '@/components/Select';
import { splitStringOnSpace } from '@/utils/common';
import { TextAreaFormik } from '@/components/TextArea';
import Loader from '@/components/Form/Loader';

export interface FormValuesType {
  name: string;
  country: string;
  email: string;
  phone: string;
  company: string;
  position: string;
  message: string;
}

interface Props {
  onSuccess: () => void;
  closeModal: () => void;
  formInnerRef: React.RefObject<HTMLDivElement>;
}

function scrollToErrorField(
  errors: FormikErrors<FormValuesType>,
  id: string,
  ref: React.RefObject<HTMLDivElement>
) {
  const el = document.getElementById(id);
  if (ref.current && el) {
    ref.current.scrollTo({
      top: el.offsetTop,
    });
  }
}

function ScrollToError(props: {
  errors: FormikErrors<FormValuesType>;
  innerRef: React.RefObject<HTMLDivElement>;
}) {
  useEffect(() => {
    const keys = Object.keys(props.errors);
    if (keys.length !== 0) {
      if (props.innerRef.current) {
        scrollToErrorField(
          props.errors,
          `${keys[0] ?? ''}-form-item`,
          props.innerRef
        );
      }
    }
  }, [props.errors]);

  return null;
}

function FormInner({ onSuccess, closeModal, formInnerRef }: Props) {
  const [loading, setLoading] = useState(false);
  const [agreementChecked, setAgreementChecked] = useState(false);
  const [submitError, setSubmitError] = useState({ status: false, value: '' });

  const title = useSettingItem('FORM_TITLE');
  const text = useSettingItem('FORM_TEXT');
  const countries = useSettingItem('FORM_COUNTRIES');
  const agreementText = useSettingItem('FORM_AGREEMENT_TEXT');

  const countriesList = splitStringOnSpace(countries) ?? [];

  async function handleSubmitForm(
    values: FormValuesType,
    formikHelpers: FormikHelpers<FormValuesType>
  ) {
    setLoading(true);
    setSubmitError({ ...submitError, status: false });
    console.log(values);
    try {
      submitLeads(values)
        .then(() => {
          if (onSuccess) onSuccess();
        })
        .catch((error) => {
          const errorMap = convertRequestErrorToMap(
            error
          ) as FormikErrors<FormValuesType>;
          formikHelpers.setErrors(errorMap);
        })
        .finally(() => {
          setLoading(false);
        });
    } catch (error) {
      setSubmitError({ ...submitError, status: true, value: error });
    }
  }

  return (
    <Component>
      <InnerWrapper ref={formInnerRef}>
        <Title>{title}</Title>
        <Text>{text}</Text>
        <Formik
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={handleSubmitForm}
          initialValues={
            {
              name: '',
              country: countriesList[0] ?? '',
              email: '',
              phone: '',
              company: '',
              position: '',
              message: '',
            } as FormValuesType
          }
        >
          {({ values, errors, handleSubmit, setFieldValue, handleChange }) => (
            <Form onSubmit={handleSubmit}>
              <FormInputs>
                <FormItem>
                  <FormLabel htmlFor={'name'}>Как вас зовут?</FormLabel>
                  <TextInputFormik
                    id={'name-form-item'}
                    name="name"
                    value={values.name}
                    error={errors.name}
                    placeholder="Фамилия Имя Отчество"
                    onChange={handleChange}
                  />
                </FormItem>
                <FormItem>
                  <FormLabel>Откуда вы?</FormLabel>
                  <Select
                    value={values.country}
                    options={countriesList}
                    setField={setFieldValue}
                    values={values}
                    name={'country'}
                    error={errors.country}
                    defaultValue={countriesList[0] ?? 'No country list'}
                  />
                </FormItem>
                <FormItem>
                  <FormLabel htmlFor={'email-form-item'}>
                    Ваш электронный адрес?
                  </FormLabel>
                  <TextInputFormik
                    id={'email-form-item'}
                    name="email"
                    value={values.email}
                    error={errors.email}
                    placeholder="Ваш электронный адрес"
                    onChange={handleChange}
                  />
                </FormItem>
                <FormItem>
                  <FormLabel htmlFor={'phone-form-item'}>
                    Номер телефона (опционально)
                  </FormLabel>
                  <TextInputFormik
                    id={'phone-form-item'}
                    name="phone"
                    value={values.phone}
                    error={errors.phone}
                    type={'tel'}
                    placeholder="+7XXXX XXX XX XX"
                    onChange={(event) => {
                      event.target.value = event.target.value.replace(
                        /[^0-9+]/,
                        ''
                      );
                      handleChange(event);
                    }}
                  />
                </FormItem>
                <FormItem>
                  <FormLabel htmlFor={'company-form-item'}>
                    Название компании?
                  </FormLabel>
                  <TextInputFormik
                    id={'company-form-item'}
                    name="company"
                    value={values.company}
                    error={errors.company}
                    placeholder="Название компании"
                    onChange={handleChange}
                  />
                </FormItem>
                <FormItem>
                  <FormLabel htmlFor={'position-form-item'}>
                    Ваша должность?
                  </FormLabel>
                  <TextInputFormik
                    id={'position-form-item'}
                    name="position"
                    value={values.position}
                    error={errors.position}
                    placeholder="Ваша должность"
                    onChange={handleChange}
                  />
                </FormItem>
              </FormInputs>
              <FormItemTextArea>
                <FormLabel htmlFor={'message-form-item'}>Сообщение</FormLabel>
                <TextAreaFormik
                  id="message-form-item"
                  name="message"
                  onChange={handleChange}
                  value={values.message}
                  error={errors.message}
                  placeholder="Введите Ваше сообщение"
                />
              </FormItemTextArea>
              <AgreementBlock>
                {agreementText ? (
                  <AgreementLeftItem>
                    <AgreementCheckBox
                      onClick={() => setAgreementChecked(!agreementChecked)}
                      agreementChecked={agreementChecked}
                    >
                      <CheckedIcon />
                    </AgreementCheckBox>

                    <AgreementText
                      dangerouslySetInnerHTML={{
                        __html:
                          agreementText.replace('<a', `<a target='_blank'`) ??
                          '',
                      }}
                    />
                  </AgreementLeftItem>
                ) : null}
                <StyledButton type="submit" disabled={!agreementChecked}>
                  {loading ? <Loader /> : 'Отправить'}
                </StyledButton>
                {submitError.status ? (
                  <FormError>
                    Что то пошло не так, обратитесь к администратору
                  </FormError>
                ) : null}
              </AgreementBlock>
              <ScrollToError innerRef={formInnerRef} errors={errors} />
            </Form>
          )}
        </Formik>
        <WrapperIcon onClick={closeModal}>
          <CloseIcon />
        </WrapperIcon>
      </InnerWrapper>
    </Component>
  );
}

const Component = styled.div`
  position: relative;
  padding: 60px 78px;
  background: ${colors.white};

  @media (max-height: 900px) {
    height: 100%;
    width: 100%;
    padding: 20px 0px;
  }

  ${media.tabletSmall(css`
    height: 100%;
    width: 100%;
    padding: 20px 0px;
  `)}
  & input {
    min-height: 48px;
    color: ${colors.dark};

    &::placeholder {
      color: ${colors.gray200};
    }
  }

  & textarea {
    min-height: 110px;
    border-radius: initial;
    padding: 14px;

    &::placeholder {
      color: ${colors.gray200};
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

const InnerWrapper = styled.div`
  overflow: auto;
  overscroll-behavior: contain;
  -webkit-overflow-scrolling: touch;

  @media (max-height: 900px) {
    padding: 60px 30px;
    height: calc(var(--vh, 1vh) * 100);
  }

  ${media.tabletSmall(css`
    padding: 60px 30px;
    height: calc(var(--vh, 1vh) * 100);
  `)};
`;

const Form = styled.form``;

const Title = styled.span`
  display: block;
  font-size: 30px;
  line-height: 130%;
`;

const Text = styled.p`
  margin-top: 20px;
  font-size: 20px;
  line-height: 150%;

  ${media.mobile(css`
    font-size: 18px;
    line-height: 150%;
  `)}
`;

const WrapperIcon = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  background: ${colors.white};

  ${media.tabletSmall(css`
    top: 40px;
  `)}
  svg line {
    stroke: ${colors.black};
  }
`;

const FormInputs = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`;

const AgreementBlock = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${media.mobile(css`
    flex-direction: column;
    align-items: flex-start;
    margin-top: 26px;

    button {
      margin-top: 26px;
    }
  `)}
`;

const AgreementCheckBox = styled.div<{ agreementChecked: boolean }>`
  position: relative;
  min-width: 20px;
  min-height: 20px;
  border: 1px solid black;
  background: white;
  margin-right: 20px;
  appearance: none;
  cursor: pointer;

  svg {
    position: absolute;
    display: ${({ agreementChecked }) => (agreementChecked ? 'block' : 'none')};
  }
`;

const AgreementLeftItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const AgreementText = styled.div`
  max-width: 260px;
  width: 100%;
  font-size: 12px;
  line-height: 20px;

  a {
    position: relative;
    display: inline-block;

    &:before {
      display: block;
      position: absolute;
      content: '';
      bottom: 0;
      width: 100%;
      height: 1px;
      background: ${colors.gradient};
      transition: opacity 250ms ease-in-out;
    }

    background: ${colors.gradient};
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

const FormItem = styled.div`
  position: relative;
  width: calc(50% - 10px);
  margin-top: 40px;

  ${media.mobile(css`
    width: 100%;
    margin-top: 30px;
  `)}
`;

const FormItemTextArea = styled.div`
  position: relative;
  margin-top: 40px;
`;

const FormLabel = styled.label`
  font-family: ${fonts.Jost};
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  display: block;
  margin-bottom: 7px;
  color: ${colors.dark};
`;

export const FormError = styled.span`
  position: absolute;
  bottom: 5%;
  right: 10%;
  color: red;
`;

export default FormInner;
