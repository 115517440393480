import React from 'react';

import { RequestError } from '@tager/web-core';

import ErrorPage from '@/pages/_error';
import { StringFieldType } from '@/typings/common';

export function convertSlugToPath(
  slug: Array<string> | string | undefined
): string {
  if (!slug) return '/';

  if (Array.isArray(slug)) {
    return slug.map(convertSlugToPath).join('');
  }

  return '/' + slug;
}

export function convertErrorToProps(
  error: Error | RequestError
): React.ComponentProps<typeof ErrorPage> {
  if ('status' in error) {
    return { statusCode: error.status.code, title: error.status.text };
  }

  return { err: error, statusCode: 500 };
}

export function splitStringOnSpace(
  value: StringFieldType
): Array<string> | undefined {
  return value?.split('\n');
}

export function getHtmlString(value: string, link = true) {
  const splitValue = value.split('');
  let isCloseTag = false;
  const linkClass = link ? '<span class="link-form">' : '<span>';

  for (let i = 0; i < splitValue.length; i++) {
    if (splitValue[i] === '*') {
      if (!isCloseTag) {
        splitValue[i] = linkClass;
        isCloseTag = !isCloseTag;
      } else {
        splitValue[i] = '</span>';
        isCloseTag = !isCloseTag;
      }
    }
  }

  return splitValue.join('');
}
