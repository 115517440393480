import React, { useEffect } from 'react';
import { useRouter } from 'next/router';

import { useModal } from '@tager/web-components';

import Form from '@/components/Form';

export interface FormContextType {
  openModal: () => void;
}

export const FormContext = React.createContext<FormContextType | null>(null);

function FormProvider({ children }: { children: React.ReactNode }) {
  const instance = useModal();

  function openModal() {
    instance(Form, {});
  }

  const values = { openModal };
  return <FormContext.Provider value={values}>{children}</FormContext.Provider>;
}

export default FormProvider;
