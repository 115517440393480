import React, { useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { ModalProps } from '@tager/web-components';
import { useScrollLockForRef } from '@tager/web-core';

import FormInner from '@/components/Form/FormInner';
import FormSuccess from '@/components/Form/FormSuccess';
import { media } from '@/utils/mixin';

function Form({ closeModal }: ModalProps) {
  const [success, setSuccess] = useState(false);
  const formInnerRef = useRef<HTMLDivElement>(null);

  useScrollLockForRef(formInnerRef);

  return (
    <Component>
      {success ? (
        <FormSuccess closeModal={closeModal} />
      ) : (
        <FormInner
          formInnerRef={formInnerRef}
          closeModal={closeModal}
          onSuccess={() => setSuccess(true)}
        />
      )}
    </Component>
  );
}

const Component = styled.div`
  max-width: 800px;
  width: 100%;

  @media (max-height: 900px) {
    min-width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
  }

  ${media.tabletSmall(css`
    min-width: 100vw;
    height: calc(var(--vh, 1vh) * 100);
  `)}
`;

export default Form;
